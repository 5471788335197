import $ from "jquery"
import modals from "bootstrap/js/dist/modal"
import CryptoJS from "crypto-js";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytesResumable, getDownloadURL , deleteObject} from "firebase/storage";
// import { getStorage, ref} from "firebase/storage";
import i18n from '@/i18n'
const firebaseConfig = {
    apiKey: "AIzaSyCRfGnfcDs7tBvesdDvsRJ4L8SXyBFq5z8",
    authDomain: "dcp-test-186fb.firebaseapp.com",
    projectId: "dcp-test-186fb",
    storageBucket: "dcp-test-186fb.appspot.com",
    messagingSenderId: "158257781334",
    appId: "1:158257781334:web:5c8499ba30688ee17e39f7",
    measurementId: "G-2KVHDBJJMT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
console.log('storage->',storage) //gs://dcp-test-186fb.appspot.com/
const storageRef = ref(storage, 'some-child');
console.log('storageRef->',storageRef)
class shareds {
    //物件建立時產生
    constructor(){
        this.weekdays = ['日', '一', '二', '三', '四', '五', '六'];
    }

    imagePath(image){
        return require(`@/assets/image/${i18n.global.locale.value}/${image}`)
    }

    //設定subtitle文字內容
    setText(data={}){
        $(data.tag).text(data.text)
    }
    //處理GCP檔案上傳
    // 處理 GCP 檔案上傳（非同步）
    async uploadFileToGCPHandle(parameter, file) {
        try {
            const metadata = { contentType: file.type };
            const url = `${process.env.VUE_APP_GCP_BUCKET}/${parameter.path}${parameter.filename}`;
            const storageRef = ref(storage, url);
            // 創建可監控的上傳任務
            const uploadTask = uploadBytesResumable(storageRef, file, metadata);
            // 監聽上傳進度
            const uploadPromise = new Promise((resolve) => {
                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log(`Upload is ${progress}% done`);
                        if (parameter.progress) parameter.progress(progress);
                    },(error) => {
                        console.error('Upload failed:', error.code);
                        if (parameter.error) parameter.error(error);
                        resolve(null);
                    },async () => {
                        try {
                            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                            console.log('File available at', downloadURL);
                            if (parameter.success) parameter.success(downloadURL);
                            resolve(downloadURL);
                        } catch (error) {
                            console.error('Error getting download URL:', error);
                            resolve(null);
                        }
                    }
                );
            });

            return await uploadPromise; // 等待上傳完成後返回結果

        } catch (error) {
            console.error('Upload error:', error);
            if (parameter.error) parameter.error(error);
            throw error;
        }
    }

    //處理gcp檔案刪除
    async delectFileToGCPHandle(parameter){
        return new Promise((resolve) => {
            try {
                const url = `${process.env.VUE_APP_GCP_BUCKET}/${parameter.path}`
                const desertRef = ref(storage, url + parameter.filename);
                deleteObject(desertRef).then((res) => {
                    // File deleted successfully
                    console.log('File deleted successfully : ' , res)
                    resolve({
                        code:0,
                        msg:'File deleted successfully : '
                    })
                }).catch((error) => {
                    console.log('Uh-oh, an error occurred:',error)
                    // Uh-oh, an error occurred!
                    resolve({
                        code:1,
                        msg:'Uh-oh, an error occurred:',error
                    })
                })
                
            } catch (error) {
                resolve({
                    code:1,
                    msg: error
                })
                throw error;
            }
        })
        
        
    }


    //顯示全畫面讀取
    LoadingStatus(status){
        if (status == 'show'){
            $('.full-seucce').css('display','flex')
        }else if (status == 'hide'){
            $('.full-seucce').css('display','none')
        }
    }

    /**********
     * 檢查物件是否不存在
     * obj : any object
     */
    isEmpty = (obj) => {
        if(
            typeof obj == 'undefined'
            || obj == null
            || obj.length<=0
        ){
            return true;
        }
        return false;
    }

    //初始化牙齒陣列
    initTeethAry(teeth=[],teeth_group=[]){
        let ary = {}
        for (let index = 18; index >= 11; --index) {
            ary[index] = {
                name:index,
                value:0,
                class:''
            }
        }
        for (let index = 21; index <= 28; index++) {
            ary[index] = {
                name:index,
                value:0,
                class:''
            }
        }
        for (let index = 38; index >= 31; --index) {
            ary[index] = {
                name:index,
                value:0,
                class:''
            }
        }
        for (let index = 41; index <= 48; index++) {
            ary[index] = {
                name:index,
                value:0,
                class:''
            }
        }

        for (let index = 0; index < teeth.length; index++) {
            const teethItem = teeth[index];
            ary[teethItem].value = 1
            ary[teethItem].class = 'select'
        }

        for (let i = 0; i < teeth_group.length; i++) {
            const groupItem = teeth_group[i];
            for (let n = 0; n < groupItem.length; n++) {
                const teethItem = groupItem[n];
                ary[teethItem].value = 2
                ary[teethItem].class = 'link'
            }
        }

        return ary
    }
    //初始化日期格式
    initDataStr(dateObj){
        let DateTime = ''
        DateTime = dateObj.getFullYear() + '/' + ( dateObj.getMonth()+1).toString().padStart(2,'0') + '/' + dateObj.getDate().toString().padStart(2,'0')
        return DateTime
    }
    //呼叫顯示popup視窗
    initModal(target){
        const modal = new modals(document.getElementById(target));
        return modal
    }
    showModal(modal){
        modal.show();
    }
    //關閉popup視窗
    hideModal(modal){
        modal.hide();
    }
    //初始化狀態陣列
    orderOrderStatus(){
        
        var status = []
        status[0] = 'Order Creating'
        status[1] = 'Order Open for Matching'
        status[2] = 'Matched, Pending Confirmation'
        status[3] = 'Impression Shipping'
        status[4] = 'Waiting for Lab Acceptance'
        status[5] = 'Order Confirmed'

        status[11] = 'Mouth sweep'
        status[12] = 'Designing'
        status[13] = 'Midway Confirmation'
        status[14] = 'QC'
        status[15] = 'Shipping Completed Prosthesis'
        status[16] = 'Awaiting Clinic Confirmation'

        status[81] = '訂單執行中'
        status[82] = '訂單執行中'

        status[91] = 'Lab Canceled'
        status[98] = 'Order Canceled'
        status[99] = 'Order Completed'

        status[100] = 'Matching Failed'
        
        return status
    }
    //轉換狀態字串
    getOrderStatusString(index){
        let statusAry = this.orderOrderStatus()
        return statusAry[index]
    }
    getOrderSendTypeString(type){
        let srt = ''
        switch (type) { //1=親送 2=貨運 3=寄送
            case 1:
                srt = '親送'
                break;
            case 2:
                srt = '貨運'
                break;
            case 3:
                srt = '寄送'
                break;
            default:
                break;
        }
        return srt
    }
    //加密
    encryptionUserInfo(data){
        console.log(data)
        var key = CryptoJS.enc.Utf8.parse("c4e1u12d45e83f78535dkb86bah50f4a");
        var iv = CryptoJS.enc.Utf8.parse("");
        let encrypted = CryptoJS.AES.encrypt(
            CryptoJS.enc.Utf8.parse(JSON.stringify(data)), 
            key, { 
                iv:iv,
                mode: CryptoJS.mode.ECB, 
                padding: CryptoJS.pad.Pkcs7
            }
        );
        console.log("encrypted=",encrypted.ciphertext.toString(CryptoJS.enc.Base64))
        return encrypted.ciphertext.toString(CryptoJS.enc.Base64)
    }

    decryptionUserInfo(data){
        var key = CryptoJS.enc.Utf8.parse("c4e1u12d45e83f78535dkb86bah50f4a");
        var decrypt = CryptoJS.AES.decrypt(
            data, 
            key,
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            }
        );
        return JSON.parse( decrypt.toString( CryptoJS.enc.Utf8 ) )
    }

    getCompName(compType){ //取得title
        // console.log('getCompName=',compType)
        let titleName = ''
        switch ( compType ) {
            case 1:
                titleName = '管理後台'
                break;
            case 2:
                titleName = '掛號系統'
                break;
            case 3:
                titleName = '診所'
                break;
            case 4:
                titleName = '技工所'
                break;
            default:
                break;
        }
        // console.log('titleName=',titleName)
        return titleName
    }

    gotoSystemView(compID){
        let url = ''
        switch ( compID ) {
            case 1:
                url = 'http://localhost:3001?token='
                break;
            case 2:
                url = 'http://localhost:3002?token='
                break;
            case 3:
                url = 'http://localhost:3001?token='
                break;
            case 4:
                url = 'http://localhost:3003?token='
                break;
            default:
                break;
        }
        return url
    }

    //換算當地時間
    getLoaclDateTime(dateTime){
        if ( dateTime == null || dateTime == '' || dateTime == -1 ){
            return ""
        }
        let timeDate = new Date(dateTime)
        let loaclTime = new Date(timeDate)
        var sy =  loaclTime.getFullYear();   
        var sm = (loaclTime.getMonth()+1).toString().padStart(2,'0');
        var sd = loaclTime.getDate().toString().padStart(2,'0');
        var sh = (loaclTime.getHours()).toString().padStart(2,'0');
        var si = (loaclTime.getMinutes()).toString().padStart(2,'0');
        var ss = loaclTime.getSeconds().toString().padStart(2,'0');

        return sy+"-"+sm+"-"+sd+" "+sh+":"+si+":"+ss;
    }
    //換算當地時間
    getLoaclDate(dateTime){
        if ( dateTime == null || dateTime == '' || dateTime == -1 ){
            return ""
        }
        let timeDate = new Date(dateTime)
        let loaclTime = new Date(timeDate)
        var sy =  loaclTime.getFullYear();   
        var sm = (loaclTime.getMonth()+1).toString().padStart(2,'0');
        var sd = loaclTime.getDate().toString().padStart(2,'0');
        
        return sy+"-"+sm+"-"+sd
    }
    //所有的id管理
    getAccountTitle(status){
        let status_name = ''
        switch (status) {
            case 1:
                status_name = '先生'
                break;
            case 2:
                status_name = '小姐'
                break;
            default:
                status_name = status
                break;
        }
        return status_name
    }
    
    getCompanyStatus(status){
        let status_name = ''
        switch (status) {
            case 1:
                status_name = '已啟用'
                break;
            case 2:
                status_name = '已停用'
                break;
            default:
                status_name = status
                break;
        }
        return status_name
    }
    getCompanyType(status){
        let status_name = ''
        switch (status) {
            case 1:
                status_name = '管理後台'
                break;
            case 2:
                status_name = '掛號系統商'
                break;
            case 3:
                status_name = '診所'
                break
            case 4:
                status_name = '技工所'
                break;
            default:
                status_name = status
                break;
        }
        return status_name
    }
    getAccountStatus(status){
        let status_name = ''
        switch (status) {
            case 1:
                status_name = '已啟用'
                break;
            case 2:
                status_name = '已停用'
                break;
            case 3:
                status_name = '等待啟用'
                break;
            case 4:
                status_name = '已逾時'
                break;
            case 5:
                status_name = '自行註冊'
                break;
            default:
                status_name = status
                break;
        }
        return status_name
    }
    getAccountJobTitle(status){
        let status_name = ''
        switch (status) {
            case 1:
                status_name = '醫師'
                break;
            case 2:
                status_name = '行政'
                break;
            default:
                status_name = status
                break;
        }
        return status_name
    }
    getOrderTypeStr(status){
        let status_name = ''
        switch (status) {
            case 1:
                status_name = '固定假牙'
                break;
            case 2:
                status_name = '活動假牙'
                break;
            default:
                status_name = status
                break;
        }
        return status_name
    }
    getRoleStatusStr(status){
        let status_name = ''
        switch (status) {
            case 1:
                status_name = '啟用'
                break;
            case 2:
                status_name = '停用'
                break;
            default:
                status_name = status
                break;
        }
        return status_name
    }

    
    //設定往後推延日期
    initEndDate(sDate,maxDay){
        let dateTime = sDate
        let _d = dateTime.setDate(dateTime.getDate()+maxDay)
        return new Date(_d)
    }

    dateFormat(date){
        var _y = date.getFullYear().toString().padStart(4,'0');
        var _m = (date.getMonth()+1).toString().padStart(2,'0');
        var _d = date.getDate().toString().padStart(2,'0');
        // console.log('return=',_y+'-'+_m+'-'+_d)
        return _y+'-'+_m+'-'+_d;
    }

    dateTimeFormat(date){
        var _y = date.getFullYear().toString().padStart(4,'0');
        var _m = (date.getMonth()+1).toString().padStart(2,'0');
        var _d = date.getDate().toString().padStart(2,'0');
        var _H = date.getHours().toString().padStart(2,'0');
        var _i = date.getMinutes().toString().padStart(2,'0');
        var _s = date.getSeconds().toString().padStart(2,'0');
        // console.log('return=',_y+'-'+_m+'-'+_d)
        return _y+'-'+_m+'-'+_d+' '+_H+':'+_i+':'+_s;
    }

    dateTimeRomoveFormat(date){
        var _y = date.getFullYear().toString().padStart(4,'0');
        var _m = (date.getMonth()+1).toString().padStart(2,'0');
        var _d = date.getDate().toString().padStart(2,'0');
        return _y+'-'+_m+'-'+_d
    }

    initEndDate(sDate,maxDay){
        let dateTime = sDate
        let _d = dateTime.setDate(dateTime.getDate()+maxDay)
        return new Date(_d)
    }

    //回傳日期 yyyy:mm:dd
    getCurrentDate() {
        //開發時要調用這個設定
        const setting = localStorage.getItem('wildone_dev_setting')
        const date = setting ? JSON.parse(setting).date : null
        if ( date ){
          return date
        }

        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    }
    //回傳日期 yyyy:mm:dd hh:ii:ss
    getCurrentDateTime() {

        //開發時要調用這個設定
        const setting = localStorage.getItem('wildone_dev_setting')
        const date = setting ? JSON.parse(setting).date : null
        const time = setting ? JSON.parse(setting).time : null
        if ( date && time ){
          return `${date} ${time}`
        }

        const now = new Date();
    
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
    
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    //回傳日期 hh:ii:ss
    getCurrentForTime(){
      //開發時要調用這個設定
      const setting = localStorage.getItem('wildone_dev_setting')
      const date = setting ? JSON.parse(setting).date : null
      const time = setting ? JSON.parse(setting).time : null
      if ( date && time ){
        return `${date} ${time}`
      }

      const now = new Date();
  
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
  
      return `${hours}:${minutes}:${seconds}`;
    }

    calculateDate(startDate, daysToAdd, excludeWeekends = false) {
        const date = new Date(startDate);
        if (isNaN(date.getTime())) {
            throw new Error('Invalid date format. Expected yyyy-mm-dd.');
        }
    
        let daysAdded = 0;
    
        while (daysAdded < daysToAdd) {
            date.setDate(date.getDate() + 1);
            if (!excludeWeekends || (date.getDay() !== 0 && date.getDay() !== 6)) {
                daysAdded++;
            }
        }
    
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
    
        return `${yyyy}-${mm}-${dd}`;
    }
    

    formatIosDate(isoDate) {

        if (
          isoDate == undefined ||
          isoDate == null ||
          isoDate == ""
        ){
          return ''
        }
  
        const date = new Date(isoDate);
        
        // 提取 UTC 時間部分
        const year = date.getUTCFullYear(); // 使用 getUTC 方法，保持 UTC 時間
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // 使用 getUTCMonth() 來避免本地時區的影響
        const day = String(date.getUTCDate()).padStart(2, '0'); // 使用 getUTCDate() 來避免本地時區的影響
        
        // 返回格式化的日期
        return `${year}-${month}-${day}`;
      }
  
      formatIosDateTime(isoDate){
          if (
            isoDate == undefined ||
            isoDate == null ||
            isoDate == ""
          ){
            return ''
          }
          const date = new Date(isoDate);
          // 提取日期部分
          const year = date.getUTCFullYear();
          const month = String(date.getUTCMonth() + 1).padStart(2, '0');
          const day = String(date.getUTCDate()).padStart(2, '0');
          // 提取時間部分
          const hours = String(date.getUTCHours()).padStart(2, '0');
          const minutes = String(date.getUTCMinutes()).padStart(2, '0');
          return `${year}-${month}-${day} ${hours}:${minutes}`;
      }

      formatDateByDate(date, format) {
        // 假設輸入日期是 'yyyy-mm-dd' 格式
        const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
        const match = date.match(regex);
  
        if (!match) {
            console.error('Invalid date format:', date);
            return 'Invalid date';
        }
  
        const [_, year, month, day] = match;
  
        // 使用 Date 物件來計算星期幾
        const d = new Date(`${year}-${month}-${day}`);
        if (isNaN(d.getTime())) {
            console.error('Invalid date:', date);
            return 'Invalid date';
        }
  
        // 根據格式返回對應部分
        switch (format) {
            case 'yyyy':
                return year;
            case 'mm':
                return month;
            case 'dd':
                return day;
            case 'day':
                return this.weekdays[d.getDay()]; // 返回星期幾
            default:
                return `${year}-${month}-${day}`; // 默認返回完整日期
        }
      }

      getDentureDataSingleTeeth(teeth=[],dentureTeeth=[]){
        let teethList = []
        for (let index = 0; index < teeth.length; index++) {
            const teethIndex = dentureTeeth.findIndex(item=>item===teeth[index]);
            if ( teethIndex !== -1 ){
                teethList.push(teeth[index])
            }
        }
        return teethList
      }

      getDentureDataGroupTeeth(teeth=[],dentureGroupTeeth=[]){
        let groupList = []
        for (let i = 0; i < dentureGroupTeeth.length; i++) {
            const group = dentureGroupTeeth[i]
            let Ary = []
            for (let n = 0; n < group.length; n++) {
                const findIndex = teeth.findIndex(item=>item===group[n])
                if ( findIndex !== -1 ){
                    Ary.push(teeth[findIndex])
                }
            }
            if ( Ary.length > 0 ){
                groupList.push(Ary)
            }

        }
        return groupList
      }

      singleTeeth(teethData){
        return Object.entries(teethData)
                .filter(([key, value]) => value.class === "select") // 篩選符合條件的項目
                .map(([key, value]) => value.index); // 只取 value.index，轉成陣列
      }
      linkTeeth(teethData){ //處理連冠
            const linkedIndexes = Object.entries(teethData)
                .filter(([key, value]) => value.class === "link") // 只保留 class 為 "link" 的項目
                .map(([key, value]) => value.index); // 只取 index

            return this.cutGroupTeeth(linkedIndexes)
        }
        cutGroupTeeth(groupTeeth) {
            let Uteeth = [18,17,16,15,14,13,12,11,21,22,23,24,25,26,27,28] //初始化上顎排隊陣列
            let Lteeth = [38,37,36,35,34,33,32,31,41,42,43,44,45,46,47,48] //初始化下顎排隊陣列

            const group = [];

            const segmentTeeth = (teethList) => {
                const result = [];
                let tempGroup = [];

                teethList.forEach(tooth => {
                    if (groupTeeth.includes(tooth)) {
                        tempGroup.push(tooth);
                    } else if (tempGroup.length > 0) {
                        result.push(tempGroup);
                        tempGroup = [];
                    }
                });

                if (tempGroup.length > 0) result.push(tempGroup); // 處理最後一組

                return result;
            };

            group.push(...segmentTeeth(Uteeth));
            group.push(...segmentTeeth(Lteeth));

            return group;
        }

        // cutGroupTeeth(groupTeeth){ //分割連冠群組
        //     console.log('cutGroupTeeth')
        //     let Uteeth = [18,17,16,15,14,13,12,11,21,22,23,24,25,26,27,28] //初始化上顎排隊陣列
        //     let Lteeth = [38,37,36,35,34,33,32,31,41,42,43,44,45,46,47,48] //初始化下顎排隊陣列
        //     //處理上顎的部分
        //     let group = []
        //     let Uary = []
        //     for (let i = 0; i < Uteeth.length; i++) {
        //         // console.log(Uteeth[i],groupTeeth.indexOf(Uteeth[i].toString(),0))
        //         if ( groupTeeth.indexOf(Uteeth[i].toString(),0) > -1 ){
        //             Uary.push(Uteeth[i].toString())
        //         }else{
        //             if ( Uary.length > 0 ){
        //                 group.push(Uary)
        //             }
        //             Uary = []
        //         }
        //     }
        //     if ( Uary.length > 0 ){
        //         group.push(Uary)
        //     }
        //     //處理下顎部分
        //     let Lary = []
        //     for (let i = 0; i < Lteeth.length; i++) {
        //         // console.log(Lteeth[i],groupTeeth.indexOf(Lteeth[i].toString(),0))
        //         if ( groupTeeth.indexOf(Lteeth[i].toString(),0) > -1 ){
        //             Lary.push(Lteeth[i].toString())
        //         }else{
        //             if ( Lary.length > 0 ){
        //                 group.push(Lary)
        //             }
        //             Lary = []
        //         }
        //     }
        //     if ( Lary.length > 0 ){
        //         group.push(Lary)
        //     }
        //     return group
        // }
      
}
export default shareds
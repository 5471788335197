<template>
  <div id="app">
    <router-view/>
    <fullScreenLoadingView></fullScreenLoadingView>
  </div>
</template>

<script>
import fullScreenLoadingView from '@/views/fullScreenLoadingView.vue'
export default {
    name: 'App',
    components: {
      fullScreenLoadingView
    },
    data(){
      return {}
    },
    watch:{
      $route(to,from){
        
      }
    },
    methods:{
      
    },
    mounted(){
    },
    created(){
      
    }
}
</script>
<style>
body{
  background-color: rgb(171 196 228 / 30%) !important;
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  color: #2c3e50 !important;
}
.view-content-box{
    width: 100%;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 2rem;
}
.content-body > div {
  position: relative;
}
@media (max-width: 948px) {
  .view-content-box{
    margin-top:4rem;
  }
}
</style>

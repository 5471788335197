class Clinic {
    constructor(apiService = null) {
        this.obj_name = 'clinic';
        this.apiService = apiService; // 將 ApiService 實例儲存為屬性
    }

    // 共用的 API 呼叫方法
    async callApi(method, endpoint, parameter = {}, progressEvent = null) {
        const apiConfig = {
            url: `${this.apiService.url}${this.obj_name}/${endpoint}`,
            parameter,
        };

        return new Promise((resolve) => {
            if (method === 'get') {
                apiConfig.url += `?${this.apiService.getMethodEncryp(parameter)}`;
                this.apiService.getApi({
                    url: apiConfig.url,
                    success: resolve,
                    error: (res) => {
                        console.log('error:', res);
                        resolve(res);
                    },
                });
            } else if (method === 'post') {
                this.apiService.postApi({
                    url: apiConfig.url,
                    parameter: apiConfig.parameter,
                    success: resolve,
                    progress: (res) => {
                        console.log(res);
                        if (progressEvent) {
                            progressEvent(res);
                        }
                    },
                    error: (res) => {
                        console.log('error:', res);
                        resolve(res);
                    },
                });
            } else if (method === 'put') {
                this.apiService.putApi({
                    url: apiConfig.url,
                    parameter: apiConfig.parameter,
                    success: resolve,
                    progress: (res) => {
                        console.log(res);
                        if (progressEvent) {
                            progressEvent(res);
                        }
                    },
                    error: (res) => {
                        console.log('error:', res);
                        resolve(res);
                    },
                });
            } else if (method === 'delete') {
                this.apiService.delApi({
                    url: apiConfig.url,
                    parameter: apiConfig.parameter,
                    success: resolve,
                    error: (res) => {
                        console.log('error:', res);
                        resolve(res);
                    },
                })
            }
        });
    }

    // API 方法
    /**
     * 取得等待邀請的帳號列表
     * 
     
     */
    async getAllPatientByClinicIdAndSnAndName(parameter){
        return this.callApi('get', 'getAllPatientByClinicIdAndSnAndName', parameter);
    }

    async getAllPatientByClinicId(parameter){
        return this.callApi('get', 'getAllPatientByClinicId', parameter);
    }

    async addPatient(parameter,progressEvent=null){
        return this.callApi('put', 'addPatient', parameter , progressEvent);
    }

    async updPatient(parameter,progressEvent=null){
        return this.callApi('put', 'updPatient', parameter , progressEvent);
    }
    
    async getPatient(parameter){
        return this.callApi('get', 'getPatient', parameter);
    }

    async getAllClinicByNameAndStatus(parameter){
        return this.callApi('get', 'getAllClinicByNameAndStatus', parameter);
    }
    async getClinic(parameter){
        return this.callApi('get', 'getClinic', parameter);
    }
}

export default Clinic;

const dentureOrderRoutes = [
    {
        path:'list',
        name:'denture-order-list',
        redirect: { name: 'denture-order-list-waitConfirm' },
        component: () => import(/* webpackChunkName: "about" */ '@/views/dentureOrder/tables/list.vue'),
        children:[{
            path:'waitConfirm',
            name:'denture-order-list-waitConfirm',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dentureOrder/tables/waitConfirm/waitConfirm.vue')
        }
        ,{
            path:'processing',
            name:'denture-order-list-processing',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dentureOrder/tables/processing.vue')
        }
        ,{
            path:'close',
            name:'denture-order-list-close',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dentureOrder/tables/close.vue')
        },{
            path:'search',
            name:'denture-order-list-search',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dentureOrder/tables/search.vue')
        }]
    },
    
    {
        path:'detail/:order_sn',
        name:'denture-order-info',
        component: () => import(/* webpackChunkName: "about" */ '@/views/dentureOrder/detail/index.vue'),
        redirect: { name: 'denture-order-info-form' },
        children:[{
            path:'form',
            name:'denture-order-info-form',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dentureOrder/detail/form.vue')
        },{
            path:'setting',
            name:'denture-order-info-setting',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dentureOrder/detail/setting.vue')
        },{
            path:'history',
            name:'denture-order-info-history',
            component: () => import(/* webpackChunkName: "about" */ '@/views/dentureOrder/detail/history.vue')
        }]

    },
    

]
export default dentureOrderRoutes
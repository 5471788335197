const clinicRoutes = [
    {
        path:'list',
        name:'clinic-list',
        component: () => import(/* webpackChunkName: "about" */ '@/views/clinic/list.vue'),
        redirect: { name: 'clinic-list-search' },
        children:[{
            path:'search',
            name:'clinic-list-search',
            component: () => import(/* webpackChunkName: "about" */ '@/views/clinic/tables/search.vue')
        }]
    },
    {
        path:'info/:clinic_id',
        name:'clinic-info',
        component: () => import(/* webpackChunkName: "about" */ '@/views/clinic/info.vue')
    },
    

]
export default clinicRoutes